<template>
  <section class="w-full bg-white">
    <div class="flex w-full p-4 mt-4">
      <img class="mr-3" src="../../../../../assets/images/hand-money-blue.svg" alt="">
      <p class="text-xl text-blue-800 font-bold">Listado Ofertas</p>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 pl-4 pr-4 pt-4 pb-2 gap-4 lg:mb-0">
      <div class="field">
        <label for="icon">Fecha inicial</label>
        <input v-model="filters.fechaInicial"
               class="p-button-secondary w-full border p-1.5 rounded-md mb-1 mt-1 flex-items-center" type="date">
      </div>
      <div class="field">
        <label for="icon">Fecha final</label>
        <input v-model="filters.fechaFinal"
               class="p-button-secondary w-full border p-1.5 rounded-md mb-1 mt-1 flex-items-center" type="date">
      </div>
      <div class="field" style="align-self: center;">
        <label for="nombre">Proveedor</label>
        <div>
          <Dropdown
            v-model="filters.proveedor"
            :options="proveedores"
            @click="$h.xxsInput"
            class="rounded-md border-gray-300 w-full p-inputtext-xs"
            optionLabel="CardName"
            panelClass="text-xxs"
            :showClear="true"
            optionValue="CardCode"
            :filter="true"
            placeholder="Seleccione un proveedor"/>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 pl-4 gap-4 pr-4 pb-4 lg:mb-0">
      <div class="field grid">
        <label>Número ofertas</label>
        <Chips
          id="numOfertas"
          :allowDuplicate="false"
          v-model="filters.numOfertas"
          separator=","
          inputClass="p-inputtext-xs"
          class="text-xs p-inputtext-xs w-full"
          placeholder="Ofertas separados por ,"
        />
      </div>
    </div>
    <div class="flex gap-4 pr-4 mb-4 lg:mb-0 justify-end pb-4">
      <div class="pb-2 flex">
        <Button class="border-0" @click="searchFilters" label="Buscar"/>
      </div>
      <div class="pb-2 flex">
        <Button class="border-0 p-button-outlined p-button-secondary" @click="clearFilters" label="Limpiar"/>
      </div>
    </div>
  </section>
  <div class="pt-6">
    <DataTable
      :value="pedidos"
      class="p-datatable-sm text-xs"
      showGridlines
      dataKey="CardCode"
      responsiveLayout="scroll"
      ref="tableList"
      v-model:expandedRows="expandedRows"
      @rowExpand="onRowExpand"
      removableSort
    >
      <template #empty>
        No hay datos para mostrar
      </template>
      <Column :expander="true" headerStyle="width: 3rem"/>
      <template #expansion="{data}">
        <DataTable
          :value="data.detalle"
          class="p-datatable-sm text-xs"
          showGridlines
          dataKey="DocEntry"
          responsiveLayout="scroll"
        >
          <Column
            field="DocNum"
            header="Nro. Oferta"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center"
          />
          <Column
            field="DocDate"
            header="Fecha creación"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center"
          />
          <Column
            field="cantidadLineas"
            header="Cantidad Mx"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center"
          />
          <Column
            field=""
            header="Total"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center"
          >
            <template #body="{data}">
              <div>
                {{ $h.formatCurrency(data.total) }}
              </div>
            </template>
          </Column>
          <!--          <Column
                      field=""
                      header="Descuento Documento"
                      headerStyle="text-align: center;justify-content: center;"
                      bodyStyle="text-align: center"
                    >
                      <template #body="{data}">
                        <div>
                          {{ $h.formatCurrency(data.descuentoDocumento) }}
                        </div>
                      </template>
                    </Column>-->
          <Column
            field=""
            header="Total IVA"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center"
          >
            <template #body="{data}">
              <div>
                {{ $h.formatCurrency(data.totalIva) }}
              </div>
            </template>
          </Column>
          <Column
            field="U_PHR_UserWs"
            header="Usuario"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center"
          />
          <Column
            header="Acciones"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center"
          >
            <template #body="{data}">
              <div>
                <Button v-tooltip.bottom="'Ver detalle Oferta'"
                        @click="viewOfertaDetail(data.DocEntry)"
                        class="p-button-primary p-button-rounded p-button-text p-button-xs"
                >
                  <i class="pi pi-eye text-base"></i>
                </Button>
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
      <Column
        field="CardCode"
        header="Código Proveedor"
        headerStyle="text-align: center;justify-content: center;"
        bodyStyle="text-align: center"
      />
      <Column
        field="CardName"
        header="Nombre Proveedor"
        headerStyle="text-align: center;justify-content: center;"
        bodyStyle="text-align: center"
      />
      <Column
        field="LineTotalAbierto"
        header="Total Lineas Abiertas"
        headerStyle="text-align: center;justify-content: center;"
        bodyStyle="text-align: center"
      >
        <template #body="{data}">
          <div class="lg:text-xs">
            <p>
              {{ $h.formatCurrency(data.LineTotalAbierto) }}
            </p>
          </div>
        </template>
      </Column>
      <!--      <Column
              field="Descuento"
              header="Total descuento"
              headerStyle="text-align: center;justify-content: center;"
              bodyStyle="text-align: center"
            >
              <template #body="{data}">
                <div class="lg:text-xs">
                  <p>
                    {{ $h.formatCurrency(data.Descuento) }}
                  </p>
                </div>
              </template>
            </Column>-->
      <Column
        field="totalIva"
        header="Total IVA"
        headerStyle="text-align: center;justify-content: center;"
        bodyStyle="text-align: center"
      >
        <template #body="{data}">
          <div class="lg:text-xs">
            <p>
              {{ $h.formatCurrency(data.totalIva) }}
            </p>
          </div>
        </template>
      </Column>
      <Column
        field="DocTotal"
        header="Vlr. Total Documento"
        headerStyle="text-align: center;justify-content: center;"
        bodyStyle="text-align: center"
      >
        <template #body="{data}">
          <div class="lg:text-xs">
            <p>
              {{ $h.formatCurrency(data.DocTotal - data.Descuento + data.totalIva) }}
              <!--              {{ $h.formatCurrency(data.DocTotal - data.Descuento + data.totalIva) }}-->
            </p>
          </div>
        </template>
      </Column>
      <Column
        field="cantidadPedidos"
        header="Cant. Ofertas"
        headerStyle="text-align: center;justify-content: center;"
        bodyStyle="text-align: center"
      />
      <Column
        field="cantidadCartas"
        header="Cant. Cartas Agotado"
        headerStyle="text-align: center;justify-content: center;"
        bodyStyle="text-align: center"
      />
      <Column
        header="Acciones"
        headerStyle="text-align: center;justify-content: center;"
        bodyStyle="text-align: center"
      >
        <template #body="{data}">
          <div>
            <Button v-tooltip.bottom="'Crear Pedido'" @click="createPedidoSap(data)"
                    class="p-button-success p-button-xs mx-2 p-button-rounded p-button-text"
            >
              <i class="pi pi-send text-base"></i>
            </Button>
          </div>
        </template>
      </Column>
    </DataTable>
    <Paginator
      v-model:first="paginate.offset"
      :rows="paginate.limit"
      :totalRecords="totalRecords"
      :rowsPerPageOptions="[10,20,30,50]"
      :pageLinkSize="5"
      @page="onPage($event)"
    />
    <modalDetalleOferta ref="modalDetalle" @updatePage="getData"/>
    <modalOfertasPrevisualizar ref="modalPrevisualizar" @updatePage="getData"/>
  </div>
</template>

<script>
import { ref, computed, onMounted, defineAsyncComponent, reactive } from 'vue'
import storeConfirmacionPedido from '../store/ofertas.store'
import dayjs from 'dayjs'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import { menuCollapse } from '@/utils/collapseMenu'

export default {
  name: 'ofertaList',
  components: {
    modalDetalleOferta: defineAsyncComponent(() => import('./components/modalDetalleOferta')),
    modalOfertasPrevisualizar: defineAsyncComponent(() => import('./components/modalPrevisualizarPedido.vue'))
  },
  setup () {
    const pedidos = computed(() => storeConfirmacionPedido.getters.getPedidosList)
    const totalRecords = computed(() => storeConfirmacionPedido.getters.getTotalRecords)
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const proveedores = ref([])
    const expandedRows = ref([])
    const modalDetalle = ref()
    const modalPrevisualizar = ref()
    const filters = reactive({
      fechaFinal: null,
      fechaInicial: null,
      proveedor: null,
      numOfertas: []
    })

    const paginate = computed(() => {
      return {
        offset: 0,
        limit: 10,
        fechaInicial: filters.fechaInicial,
        fechaFinal: filters.fechaFinal,
        proveedor: filters.proveedor,
        numOfertas: filters.numOfertas
      }
    })
    const getData = () => {
      expandedRows.value = []
      const params = {
        paginate: paginate.value
      }
      storeConfirmacionPedido.dispatch('getList', params)
    }
    const onRowExpand = ({ data }, index) => {
      const params = {
        fechaInicial: filters.fechaInicial,
        fechaFinal: filters.fechaFinal,
        CardCode: data.CardCode,
        numOfertas: filters.numOfertas
      }
      storeConfirmacionPedido.dispatch('getOfertaByCardCode', params).then(({ data }) => {
        storeConfirmacionPedido.commit('setDetailsByCardCode', data)
      })
    }
    const createPedidoSap = async ({ CardCode }) => {
      const params = {
        CardCode: CardCode,
        fechaInicial: filters.fechaInicial,
        fechaFinal: filters.fechaFinal,
        numOfertas: filters.numOfertas
      }
      await storeConfirmacionPedido.dispatch('getDataOfertaPrevisualizar', params).then(({ data }) => {
        modalPrevisualizar.value.openModal(data)
      })
    }
    const searchFilters = () => {
      getData()
    }
    const clearFilters = () => {
      filters.proveedor = null
      filters.numOfertas = []
      getData()
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      paginate.value.limit = rows
      paginate.value.offset = first
      getData()
    }
    const getProveedores = () => {
      _RecepcionPedidosService.value.getProveedores().then(({ data }) => {
        proveedores.value = data
      })
    }
    /* obtener detalle de la oferta de compra y visualiza en modal */
    const viewOfertaDetail = async (DocEntry) => {
      modalDetalle.value.openModal(DocEntry)
    }
    onMounted(() => {
      const date = new Date()
      const now = new Date()
      const lastMonth = date.setMonth(date.getMonth() - 1)
      filters.fechaInicial = dayjs(lastMonth).format('YYYY-MM-DD')
      filters.fechaFinal = dayjs(now).format('YYYY-MM-DD')
      getData()
      getProveedores()
      menuCollapse()
    })
    return {
      viewOfertaDetail,
      createPedidoSap,
      onPage,
      pedidos,
      totalRecords,
      expandedRows,
      modalDetalle,
      paginate,
      filters,
      searchFilters,
      clearFilters,
      getProveedores,
      proveedores,
      modalPrevisualizar,
      onRowExpand,
      getData
    }
  }
}
</script>

<style scoped>

::v-deep(.p-column-header-content) {
  justify-content: center;
}

.p-chips-token {
  padding: 0.1rem 0.5rem;
  margin-right: 0.4rem;
  margin-top: 2px;
  margin-bottom: 2px;
}

::v-deep(.p-chips-multiple-container) {
  padding: 0.1rem 0.6rem;
  max-height: 70px;
  overflow-y: auto;
}

:global(.swal2-container) {
  z-index: 1000000 !important
}
</style>
