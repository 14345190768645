import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_RECEPCION_PEDIDOS

const getListConfirmarPedidos = (payload) => {
  return http.get(`${baseUrl}/oferta/list`, {
    params: { ...payload },
    headers: {
      loading: true
    }
  })
}
const getDetailOfertaCompra = (DocEntry) => {
  return http.get(`${baseUrl}/oferta/get-detail/${DocEntry}`, {
    headers: {
      loading: true
    }
  })
}
const getDataOfertaPrev = (payload) => {
  return http.get(`${baseUrl}/oferta/preview-purchase-order`, {
    params: { ...payload },
    headers: {
      loading: true
    }
  })
}
const getOfertaByCardCode = (payload) => {
  return http.get(`${baseUrl}/oferta/get-oferta-by-carcode`, {
    params: { ...payload },
    headers: {
      loading: true
    }
  })
}
const getDistribucionMx = ({ DocNumOferta, LineNumPedido }) => {
  return http.get(`${baseUrl}/oferta/get-cantidaes/${DocNumOferta}/${LineNumPedido}`, {
    headers: {
      loading: true
    }
  })
}
const updateModalDetalle = (payload) => {
  return http.put(`${baseUrl}/oferta/update-line/${payload.DocEntry}/1`, payload.data, {
    headers: {
      loading: true
    }
  })
}
const updateModalCantidades = (payload) => {
  return http.put(`${baseUrl}/oferta/update-line/${payload.DocEntry}/0`, payload.detalle, {
    headers: {
      loading: true
    }
  })
}
const searchMxAgrupacion = (payload) => {
  return http.get(`${baseUrl}/medicamento/search-mx`, {
    params: { ...payload },
    headers: {
      loading: true
    }
  })
}

const createPedidoSap = (body) => {
  return http.post(`${baseUrl}/oferta/create-purchase-order`, body, {
    headers: {
      loading: true
    }
  })
}

const cancelOfertaSap = (DocEntry) => {
  return http.post(`${baseUrl}/oferta/cancel-oferta/${DocEntry}`, { }, {
    headers: {
      loading: true
    }
  })
}
const cancelLineaSap = (payload) => {
  return http.put(`${baseUrl}/oferta/update-line/${payload.DocEntry}/2`, payload.data, {
    headers: {
      loading: true
    }
  })
}

const getListCartasMd = (ItemCode) => {
  return http.get(`${baseUrl}/medicamento/cartas-agotado/${ItemCode}`, {}, {
    headers: {
      loading: true
    }
  })
}
const getLineasPreviewOferta = (DocEntryPedidoBase) => {
  return http.get(`${baseUrl}/oferta/preview-oferta-compra/${DocEntryPedidoBase}`, {}, {
    headers: {
      loading: true
    }
  })
}
const postOferta = (payload) => {
  return http.post(`${baseUrl}/oferta/create-purchase-quotations/${payload.DocEntry}`, payload.data, {
    headers: {
      loading: true
    }
  })
}

export {
  getListConfirmarPedidos,
  getDetailOfertaCompra,
  getDataOfertaPrev,
  getOfertaByCardCode,
  getDistribucionMx,
  updateModalDetalle,
  updateModalCantidades,
  searchMxAgrupacion,
  createPedidoSap,
  cancelOfertaSap,
  cancelLineaSap,
  getListCartasMd,
  getLineasPreviewOferta,
  postOferta
}
