import { createStore } from 'vuex'
import {
  getListConfirmarPedidos,
  getDetailOfertaCompra,
  getDataOfertaPrev,
  getOfertaByCardCode,
  getDistribucionMx,
  updateModalDetalle,
  updateModalCantidades,
  searchMxAgrupacion,
  cancelLineaSap
} from '../services/ofertas.service'

const storeConfirmacionPedido = {
  state: {
    _pedidosList: [],
    _totalRecords: 0
  },
  getters: {
    getPedidosList: state => state._pedidosList,
    getTotalRecords: state => state._totalRecords
  },
  mutations: {
    setPedidosList (state, payload) { state._pedidosList = payload },
    setTotalRecords (state, payload) { state._totalRecords = payload },
    setDetailsByCardCode (state, payload) {
      state._pedidosList.forEach((element) => {
        if (element.CardCode === payload[0].CardCode) {
          element.detalle = payload
        }
      })
      state._pedidosList.detalle = payload
    }
  },
  actions: {
    getList ({ commit }, payload) {
      return getListConfirmarPedidos(payload).then(({ data }) => {
        commit('setPedidosList', data.records)
        commit('setTotalRecords', data.count)
      })
    },
    getDetailOferta (state, DocEntry) {
      return getDetailOfertaCompra(DocEntry)
    },
    getDataOfertaPrevisualizar (state, payload) {
      return getDataOfertaPrev(payload)
    },
    getOfertaByCardCode (state, payload) {
      return getOfertaByCardCode(payload)
    },
    getCantidadesMx (state, payload) {
      return getDistribucionMx(payload)
    },
    updateModalDetalle (state, payload) {
      return updateModalDetalle(payload)
    },
    updateModalCantidades (state, payload) {
      return updateModalCantidades(payload)
    },
    searchMxAgrupacion (state, payload) {
      return searchMxAgrupacion(payload)
    },
    closeLinea (state, payload) {
      return cancelLineaSap(payload)
    }
  }
}

const store = createStore(storeConfirmacionPedido)
export default {
  namespaced: true,
  ...store
}
